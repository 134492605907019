/* global USE_SERVICE_WORKER */
import './webpack-imports';
import './sentry';
import './polyfills';

import sw from './sw';
import { registerCustomElements } from './custom-elements';
import LazyLoad from 'vanilla-lazyload';
import Swiper, { Pagination, Autoplay } from 'swiper';
import Modal from './components/modal';
import Tab from './components/tab';
import Accordeon from './components/accordeon';
import axios from 'axios';
import delegate from 'delegate';
import IMask from 'imask';
import validate from './components/validate';
import { onFocus, onBlur, onReady } from './components/input-focus';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import smoothscroll from 'smoothscroll-polyfill';

document.addEventListener('DOMContentLoaded', () => {
    let header = document.querySelector('.header');
    let headerHeight = header.offsetHeight;
    if (window.matchMedia('(min-width: 1280px)').matches) {
        headerHeight = 0;
    }
    window.addEventListener('resize', () => {
        if (window.matchMedia('(min-width: 1280px)').matches) {
            headerHeight = 0;
        } else {
            headerHeight = header.offsetHeight;
        }
    });
    if (window.location.hash) {
        let elementOffset = document.querySelector(window.location.hash)?.offsetTop;
        let scrollPosition = elementOffset - headerHeight;
        setTimeout(()=> {
        window.scrollTo(0,0)
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
        }, 1)
    }
    // Forces repaint, use when really needed.
    // document.documentElement.classList.add('js-ready');
    registerCustomElements();
    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });
    lazyLoadInstance.update();
    smoothscroll.polyfill();
    Swiper.use(Pagination);
    Swiper.use([Autoplay]);
    function initSwiper(container) {
        var mySwiper = new Swiper(container, {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            autoHeight: true,
            breakpoints: {
                320: {
                    spaceBetween: 50,
                },
                960: {
                    spaceBetween: 200,
                },
            },
            on: {
                slideChange: function() {
                    mySwiper?.pagination.update();
                    this?.pagination.update();
                }
            }
        });
        container.addEventListener('mouseenter', function() {
            mySwiper.autoplay.stop();
        });
        container.addEventListener('mouseleave', function() {
            mySwiper.autoplay.start();
        });
    }
    const articlesSelector = document.querySelector('.js-article-slider');
    if (articlesSelector) {
        setTimeout(() => {
            initSwiper(articlesSelector);
        }, 300);
    }
    const detalPictures = document.querySelector('.js-detal-pictures');
    if (detalPictures) {
        setTimeout(() => {
            initSwiper(detalPictures);
        }, 300);
    }

    function revealInit() {
        const revealItemsObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    revealItemsObserver.unobserve(entry.target);
                    entry.target.classList.add('revealed');
                }
            });
        });

        Array.from(document.querySelectorAll('.js-reveal')).forEach((el) => {
            revealItemsObserver.observe(el);
        });
    }
    revealInit();

    const tabs = Array.from(document.querySelectorAll('[data-tabs]'));
    tabs.forEach((tab) => {
        tab = new Tab(tab);
    });

    delegate(
        '[data-accordeon]',
        'click',
        function (e) {
            e.delegateTarget = new Accordeon(e.delegateTarget);
        },
        false,
    );

    const links = Array.from(document.querySelectorAll('[data-drop-toggle]'));

    links.forEach((link) => {
        link = new Modal(link);
    });

    const forms = Array.from(document.querySelectorAll('.js-form'));

    forms.forEach((form) => {
        validate(form);
    });

    const inputs = Array.from(document.querySelectorAll('.js-input-focus'));

    inputs.map((item) => {
        item.addEventListener('focus', onFocus);
        item.addEventListener('blur', onBlur);
        onReady(item);
    });

    const radiosContainer = document.querySelector('.js-radios');
    const radios = Array.from(document.querySelectorAll('.js-radios input[type="radio"]'));
    const form = radiosContainer?.parentNode?.parentNode;
    if (form) {
        const phoneLabel = form.querySelector('[data-input="phone"]');
        const mailLabel = form.querySelector('[data-input="mail"]');
        const phoneInput = phoneLabel?.querySelector('input');
        const mailInput = mailLabel?.querySelector('input');
        radios.forEach((radio) => {
            radio.addEventListener('change', function (e) {
                radiosContainer?.classList.remove('is-error');
                if (e.target.dataset.type == 'phone') {
                    mailLabel?.classList.add('is-disabled');
                    phoneLabel?.classList.remove('is-disabled');
                    phoneInput?.setAttribute('data-input-required', '');
                    mailInput?.removeAttribute('data-input-required');
                    mailInput?.classList.remove('is-error');
                } else if (e.target.dataset.type == 'mail') {
                    mailLabel?.classList.remove('is-disabled');
                    phoneLabel?.classList.add('is-disabled');
                    phoneInput?.removeAttribute('data-input-required');
                    phoneInput?.classList.remove('is-error');
                    mailInput?.setAttribute('data-input-required', '');
                }
            });
        });
    }
    const tels = Array.from(document.querySelectorAll('input[data-mask="tel"]'));

    tels?.map((tel) => {
        const imask = IMask(tel, { mask: '+{7} (000) 000-00-00' });
    });

    const calendarEl = Array.from(document.querySelectorAll('.js-calendar'));

    calendarEl.forEach((calendar) => {
        flatpickr(calendar, {
            altInput: true,
            locale: Russian,
            minDate: 'today',
            maxDate: new Date().fp_incr(60),
        });
    });

    const timeEl = Array.from(document.querySelectorAll('.js-time'));

    timeEl.forEach((time) => {
        flatpickr(time, {
            locale: 'ru',
            noCalendar: true,
            time_24hr: true,
            enableTime: true,
            minTime: '8:00',
            maxTime: '23:00',
        });
    });
    
    const anchorLinks = Array.from(document.querySelectorAll('.js-anchor'));
    anchorLinks.forEach((link) => {
        link.addEventListener('click', function (e) {
            e.preventDefault();
            if (window.matchMedia('(max-width: 1279px)').matches) {
                hide('burger');
            }
            window.location.href = e.target.href;
            let elementOffset = document.getElementById(e.target.href.split('#')[1])?.offsetTop;
            let scrollPosition = elementOffset - headerHeight;
            window.scrollTo(0,0)
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        });
    });
    let toTop = document.querySelector(".js-to-top");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        toTop?.classList.add('is-in')
    } else {
        toTop?.classList.remove('is-in')
    }
    }

    toTop.addEventListener('click', topFunction)

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    scrollFunction();

    const exportWysiwyg = document.querySelector('.export-wysiwyg');

    function toggleSpoiler() {

        const ulWrapper = this.closest('ul');
        const hiddenPart = ulWrapper.querySelector('.ul-hidden-part');
        let timer;
        if (!hiddenPart.classList.contains('is-anim')) {
            if (hiddenPart.offsetHeight === 0) {
                hiddenPart.style.maxHeight = `${hiddenPart.scrollHeight}px`;
                hiddenPart.classList.add('is-anim');
                timer = setTimeout(() => {
                    hiddenPart.classList.remove('is-anim');
                    this.innerHTML = this.dataset.hideText;
                }, 400);
            } else {
                hiddenPart.style.maxHeight = '';
                hiddenPart.classList.add('is-anim');
                timer = setTimeout(() => {
                    hiddenPart.classList.remove('is-anim');
                    this.innerHTML = this.dataset.showText;
                }, 400);
            }
        }
    }

    if (exportWysiwyg) {
        const ulsInExportWysiwyg = exportWysiwyg.querySelectorAll('ul');
        if (ulsInExportWysiwyg && ulsInExportWysiwyg.length > 0) {
            ulsInExportWysiwyg.forEach(ul => {
                const li = ul.querySelectorAll('li');
                const hiddenLis = [];
                if (li.length > 3) {
                    li.forEach((item, i) => {
                        if (i > 2) {
                            hiddenLis.push(item);
                        }
                    })
                }
                if (hiddenLis.length > 0) {
                    let hiddensWrapper = document.createElement('div');
                    hiddensWrapper.classList.add('ul-hidden-part');
                    const hideLink = document.createElement('a');
                    hideLink.setAttribute('data-hide-text', 'Скрыть');
                    hideLink.setAttribute('data-show-text', 'Раскрыть');
                    hideLink.innerHTML = hideLink.dataset.showText;
                    hideLink.classList.add('link');
                    hideLink.classList.add('link--dashed');
                    hideLink.addEventListener('click', toggleSpoiler)
                    ul.appendChild(hiddensWrapper);
                    ul.appendChild(hideLink);
                    hiddenLis.forEach(li => {
                        const newLi = li.cloneNode(true);
                        li.remove();
                        hiddensWrapper.appendChild(newLi);
                    })
                }
            });
        }
    }

    const fileInputs = Array.from(document.querySelectorAll('.js-input-file'));

    fileInputs.forEach(input => {
        input.addEventListener('change', function(e) {
            let fileName = '';
            if (e.target.value) {
                fileName = e.target.value.split('\\').pop();
                const inputLabel = e.target.closest('.file-label').querySelector('span');
                let oldVal = inputLabel.innerHTML;
                if (fileName !== '') {
                    inputLabel.innerHTML = fileName;
                    e.target.closest('.file-label').classList.add('is-fill')
                } else {
                    inputLabel.innerHTML = oldVal;
                }
            }
        })
    })

    const menuLinks = Array.from(document.querySelectorAll('.menu-item'));
    menuLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            if (e.target.getAttribute('href') === '#') {
                e.preventDefault();
            }
        })
    })
    // Code here
});

// Service Worker
if (USE_SERVICE_WORKER) {
    window.addEventListener('load', () => sw.register());
} else {
    sw.unregister();
}
