export default function Accordeon(payload) {

    const selector = payload.dataset.accordeon;
    const openers = Array.from(document.querySelectorAll(`[data-accordeon=${selector}]`));
    const element = document.querySelector(`[data-accordeon-id=${selector}]`);
    if (element.classList.contains('is-open')) {
        hide(element);
    } else {
        show(element);
    }

    function show(e) {
        openers.forEach(item => item.classList.add('is-open'));
        if (element.dataset.accordeonGroup !== null) {
            let groupEls = Array.from(document.querySelectorAll(`[data-accordeon-group=${element.dataset.accordeonGroup}]`));
            groupEls.forEach(item => {
                if (item !== element) {
                    let id = item.dataset.accordeonId;
                    item.classList.remove('is-open');
                    item.style.maxHeight = null;
                    item.style.visibility = 'hidden';
                    item.style.height = 0;
                    let openers = Array.from(document.querySelectorAll(`[data-accordeon=${id}]`));
                    openers.forEach(item => item.classList.remove('is-open'));
                }
            });
        }
        element.classList.add('is-open');
        openers.forEach(item => item.classList.add('is-open'));
        if (element.dataset.accordeonGroup !== null) {
            if (element.dataset.noScroll !== 'no') {
                let header = document.querySelector('.header');
                let headerHeight = header.offsetHeight;
                let windowOffset = window.pageYOffset;
                let elementOffset = element.getBoundingClientRect().top;
                let scrollPosition = windowOffset - headerHeight + elementOffset - 100;
                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth"
                });
            }
        }
        element.style.maxHeight = element.scrollHeight + 'px';
        element.style.visibility = 'visible';
        element.style.height = element.scrollHeight + 'px';
        for (var target = payload; target && target != this; target = target.parentNode) {
            if (target.dataset) {
                if (target.dataset.accordeonId) {
                    target.style.height = 'auto';
                    target.style.maxHeight = null;
                    target.classList.add('is-inner-open');
                }
            }
        }
    }

    function hide(e) {
        for (var target = payload; target && target != this; target = target.parentNode) {
            if (target.dataset) {
                if (target.dataset.accordeonId) {
                    target.style.height = 'auto';
                    target.style.maxHeight = null;
                }
            }
        }
        if (element.classList.contains('is-inner-open')) {
            element.style.maxHeight = element.scrollHeight + 'px';
            element.style.visibility = 'visible';
            element.style.height = element.scrollHeight + 'px';
        }
        setTimeout(()=> {
            openers.forEach(item => item.classList.remove('is-open'));
            element.classList.remove('is-open');
            element.style.maxHeight = null;
            element.style.visibility = 'hidden';
            element.style.height = 0;
        }, 1)

    }

    function hideAll() {
        const allOpeners = Array.from(document.querySelectorAll(`[data-accordeon]`));
        allOpeners.forEach(item => item.classList.remove('is-open'));
        const allElements = Array.from(document.querySelectorAll(`[data-accordeon-id]`));
        allElements.forEach(item => {
            item.classList.remove('is-open');
            item.style.maxHeight = null;
            item.style.visibility = 'hidden';
            item.style.height = 0;
        })
    }

    window.addEventListener('keyup', function (e) {
        if (e.keyCode == '27') {
            hideAll();
        }
    });

    function throttle(func, ms) {
        let isThrottled = false,
          savedArgs,
          savedThis;
        function wrapper() {
          if (isThrottled) { // (2)
            savedArgs = arguments;
            savedThis = this;
            return;
          }
          func.apply(this, arguments); // (1)
          isThrottled = true;
          setTimeout(function() {
            isThrottled = false; // (3)
            if (savedArgs) {
              wrapper.apply(savedThis, savedArgs);
              savedArgs = savedThis = null;
            }
          }, ms);
        }
        return wrapper;
    }

    window.addEventListener('resize', function() {
        throttle(function() {
            openers.filter(item => item.classList.contains('is-open')).forEach(item => {
                item.style.maxHeight = 0;
                item.style.height = 0;
                item.style.maxHeight = item.scrollHeight + 'px';
                item.style.height = item.scrollHeight + 'px';
            })
        }, 100)
    })
}