import axios from 'axios';
import { forEach } from 'core-js/fn/array';

export default function validate(container) {

    let form = container.querySelector('[data-form-validate]'),
        thanksBlocks = Array.from(container.querySelectorAll('[data-form-thanks-block]')),
        errorBlocks = Array.from(container.querySelectorAll('[data-form-error-block]')),
        formSubmit = form.querySelector('[data-form-submit]'),
        formCloses = Array.from(container.querySelectorAll('[data-form-close]')),
        inputsArr = [],
        radioContainer = form.querySelector('.js-radios'),
        radios = Array.from(container.querySelectorAll('input[type="radio"]'));

    function is_valid_inn(i) {
        if ( i.match(/\D/) ) return false;

        var inn = i.match(/(\d)/g);
        if ( inn.length == 10 )
        {
            return inn[9] == String(((
                2*inn[0] + 4*inn[1] + 10*inn[2] +
                3*inn[3] + 5*inn[4] +  9*inn[5] +
                4*inn[6] + 6*inn[7] +  8*inn[8]
            ) % 11) % 10);
        }
        else if ( inn.length == 12 )
        {
            return inn[10] == String(((
                    7*inn[0] + 2*inn[1] + 4*inn[2] +
                10*inn[3] + 3*inn[4] + 5*inn[5] +
                    9*inn[6] + 4*inn[7] + 6*inn[8] +
                    8*inn[9]
            ) % 11) % 10) && inn[11] == String(((
                3*inn[0] +  7*inn[1] + 2*inn[2] +
                4*inn[3] + 10*inn[4] + 3*inn[5] +
                5*inn[6] +  9*inn[7] + 4*inn[8] +
                6*inn[9] +  8*inn[10]
            ) % 11) % 10);
        }

        return false;
    }

    function updateInputsList() {
        inputsArr = [];
        let inputs = form.querySelectorAll('[data-input-required]');
        for (let i = 0; i < inputs.length; i++) {
            let inputsItem = {
                item: inputs[i],
                ready: false,
                dirty: false,
                name: inputs[i].name
            };
            if (inputs[i].type == "checkbox" && inputs[i].checked) {
                inputsItem.ready = true
            }
            if (inputs[i].type == 'tel') {
                inputsItem.ready = inputs[i].value.length == 18;
            }
            if (inputs[i].type == 'email') {
                inputsItem.ready = /^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i.test(inputs[i].value);
            }
            if (inputs[i].type == 'text' || inputs[i].type == 'hidden' ) {
                if (inputs[i].name == 'inn') {
                    inputsItem.ready = inputs[i].value.length > 0 && is_valid_inn(inputs[i].value);
                } else {
                    inputsItem.ready = inputs[i].value.length > 0;
                }
            }
            inputsArr.push(inputsItem);
        }
        for (let i = 0; i < inputsArr.length; i++) {
            inputsArr[i].item.addEventListener('blur', function (e) {
                if (inputsArr[i].item.type == 'tel') {
                    (e.target.value.length == 18) ? inputsArr[i].ready = true: inputsArr[i].ready = false;
                } else if (inputsArr[i].item.type == 'email') {
                    /^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i.test(e.target
                        .value) ? inputsArr[i].ready = true : inputsArr[i].ready = false;
                } else {
                    if (inputsArr[i].item.name == 'inn') {
                        is_valid_inn(e.target.value) ? inputsArr[i].ready = true : inputsArr[i]
                        .ready = false;
                    } else {
                        e.target.value.length > 0 ? inputsArr[i].ready = true : inputsArr[i]
                            .ready = false;
                    }
                }
                checkReady();
            })
            inputsArr[i].item.addEventListener('focus', (e) => {
                inputsArr[i].dirty = true;
            })
        }

        for (let i = 0; i < inputsArr.length; i++) {
            if (inputsArr[i].item.type == 'checkbox') {
                inputsArr[i].item.addEventListener('change', (e) => {
                    e.target.checked ? inputsArr[i].ready = true : inputsArr[i].ready = false;
                    inputsArr[i].dirty = true;
                    checkReady();
                })
            }
        }

        for (let i = 0; i < inputsArr.length; i++) {
            if (inputsArr[i].item.type == 'radio') {
                inputsArr[i].item.addEventListener('change', (e) => {
                    for (let i = 0; i < inputsArr.length; i++) {
                        if (inputsArr[i].name == e.target.name) {
                            inputsArr[i].ready = true;
                        }
                    }
                    checkReady();
                })
            }
        }
    }
    updateInputsList()

    function checkReady() {
        let readyCheck = true;
        for (let i = 0; i < inputsArr.length; i++) {
            readyCheck *= inputsArr[i].ready;
            if (!inputsArr[i].ready && inputsArr[i].dirty) {
                inputsArr[i].item.classList.add('is-error');
                inputsArr[i].item.classList.remove('is-success');
            } else if (inputsArr[i].ready && inputsArr[i].dirty) {
                inputsArr[i].item.classList.remove('is-error');
                inputsArr[i].item.classList.add('is-success');
            } else {
                inputsArr[i].item.classList.remove('is-error');
            }
        }

        if (radioContainer) {
            let isAnyChecked = 0;

            if (radios.length > 0) {
                radios.forEach(radio => {
                    isAnyChecked += radio.checked
                })
            }
            readyCheck *= isAnyChecked;
            if (!isAnyChecked) {
                radioContainer.classList.add('is-error');
            }
        }
        readyCheck ? formSubmit.classList.remove('is-disabled') : formSubmit.classList.add('is-disabled');

        return readyCheck;
    }

    function showThanks() {
        thanksBlocks?.forEach(block => {
            block.classList.add('is-open');
            block.addEventListener('click', function(){
                thanksBlocks?.forEach(block => {
                    block.classList.add('is-out');
                    setTimeout(function() {
                        block.classList.remove('is-out');
                        block.classList.remove('is-open');
                    }, 300)
                });
            })
        });
    }

    function showError() {
        errorBlocks?.forEach(block => {
            block.classList.add('is-open');
            block.addEventListener('click', function(){
                errorBlocks?.forEach(block => {
                    block.classList.add('is-out');
                    setTimeout(function() {
                        block.classList.remove('is-out');
                        block.classList.remove('is-open');
                    }, 300)
                });
            })
        });
    }

    function makeAllDirty() {
        for (let i = 0; i < inputsArr.length; i++) {
            inputsArr[i].dirty = true;
        }
    }

    function clearAll() {
        for (let i = 0; i < inputsArr.length; i++) {
            inputsArr[i].ready = false;
            if (inputsArr[i].item.type == 'hidden') {
                inputsArr[i].ready = true;
            }
            if (inputsArr[i].item.type == 'checkbox') {
                inputsArr[i].item.checked = false;
            }
            inputsArr[i].dirty = false;
            inputsArr[i].item.value = '';
            inputsArr[i].item.classList.remove('is-fill');
            inputsArr[i].item.classList.remove('is-error');
            inputsArr[i].item.classList.remove('is-success');
        }
    }


    if (formCloses) {
        formCloses.forEach(close => {
            close.addEventListener('click', (e) => {
                e.preventDefault();
                clearAll();
            })
        });
    }

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        updateInputsList();
        makeAllDirty();
        if (!checkReady()) {
            return
        }
        e.target.classList.add('is-load');


        let formData = new FormData(e.target);
        axios.post(e.target.action, formData)
        .then(res => {
            e.target.classList.remove('is-load');
            showThanks();
        })
        .catch(res => {
            e.target.classList.remove('is-load');
            showError();
        })
        .finally(res => {
            e.target.classList.remove('is-load');
            clearAll();
            // hide(formCloses[0]?.dataset.dropToggle);
        });
    })
}