export default function Tab(container){
    let currentTab = container.querySelector('.js-tab-content.is-in'),
        links = Array.from(container.querySelectorAll('.js-tab-control .js-tab-link')),
        currentLink = container.querySelector('.js-tab-link.is-in'),
        oldLink = currentLink,
        oldTab = currentTab;
    links.map(function(link){
        link.addEventListener('click', function(e) {
            e.preventDefault();
            oldLink = currentLink;
            currentLink = this;
            oldLink?.classList.remove('is-in');
            currentLink?.classList.add('is-in');
            oldLink?.classList.remove('is-current');
            currentLink?.classList.add('is-current');
            oldTab = currentTab;
            currentTab = container.querySelector(this.getAttribute('href'));
            oldTab?.classList.remove('is-in');
            oldTab?.classList.add('is-out');
            oldTab?.classList.remove('is-out')
            currentTab?.classList.add('is-in');
        })
    })
}